import {
  ApolloClient
} from 'apollo-client';
import {
  createHttpLink,
} from 'apollo-link-http'
import {
  InMemoryCache
} from 'apollo-cache-inmemory'
import {
  ApolloLink
} from 'apollo-link'
import {
  SHOPIFY
} from "./constants"

function getApolloClient() {
  const interceptor = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({
      headers = {}
    }) => ({
      headers: {
        ...headers,
        'X-Shopify-Storefront-Access-Token': SHOPIFY.STOREFRONT_TOKEN
      }
    }));
    return forward(operation);
  })

  const abortController = new AbortController();
  // HTTP connection to the API
  const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri: SHOPIFY.URL,
    fetchOptions: {
      mode: "cors",
      signal: abortController.signal,
    },
  })

  const authLink = interceptor.concat(httpLink)

  // Cache implementation
  const cache = new InMemoryCache()

  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }


  // Create the apollo client
  const apolloClient = new ApolloClient({
    link: authLink,
    cache,
    defaultOptions
  })

  return apolloClient
}

const apolloClient = getApolloClient()

export async function query(query, variables) {

  try {
    return await apolloClient.query({
      query: query,
      variables: variables
    })
  } catch (error) {
    return {
      error
    }
  }
}

export async function mutate(mutation, variables) {

  try {
    const {
      data
    } = await apolloClient.mutate({
      mutation: mutation,
      variables: variables
    })
    return data
  } catch (error) {
    return {
      error
    };
  }
}

export function errorMessage(errors) {

  let messages

  if (errors) {
    messages = errors.map((e) => {
      const {
        message
      } = e
      return message
    })
  }

  if (messages) {
    return messages.join(";")
  }

  return null
}