import {
    getExchangeRate
} from './token'

export function formatTime(timestamp) {
    var date = new Date(timestamp);
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    var day = date.getDate()
    var hour = date.getHours()
    var minute = date.getMinutes()
    var second = date.getSeconds()

    return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':')
}

export function formatUtcTime(utcTime) {
    if (!utcTime)
        return '-'
    var date = new Date(utcTime);
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    var day = date.getDate()
    var hour = date.getHours()
    var minute = date.getMinutes()
    var second = date.getSeconds()

    return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':')
}


export function formatShortTime(timestamp) {
    var date = new Date(timestamp);
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    var day = date.getDate()
    var hour = date.getHours()
    var minute = date.getMinutes()
    var second = date.getSeconds()
    var currentYear = new Date().getFullYear();
    if (year == currentYear) {
        return [month, day].map(formatNumber).join('-') + ' ' + [hour, minute].map(formatNumber).join(':')
    } else {
        return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute].map(formatNumber).join(':')
    }
}

export function transStringToTimeStamp(s) {
    const date = new Date(s);
    return date.getTime()
}

// '1': '您已提交转运单，包裹发往转运仓',
// '2': '已到达转运仓，即将发往泰国',
// '3': '已发往泰国仓',
// '4': '已到达泰国仓，请您及时付款，付款后将配送上门',
// '5': '已到达泰国仓，请您及时付款，付款后将配送上门',
// '6': '泰国快递派送到家中',
// '7': '您已签收，感谢使用小蜜蜂转运'
export function formatCargoStatusTime(cargo) {
    var statusTime = null;
    if (cargo.status == 1) {
        statusTime = cargo.createdAt
    } else if (cargo.status == 2) {
        statusTime = cargo.transitAt
    } else if (cargo.status == 3) {
        statusTime = cargo.transitDeliverAt
    } else if (cargo.status == 4 || cargo.status == 5) {
        statusTime = cargo.fReceivedAt
    } else if (cargo.status == 6) {
        statusTime = cargo.fDeliverAt
    } else if (cargo.status == 7) {
        statusTime = cargo.custReceivedAt
    } else if (cargo.status == 10) {
        statusTime = cargo.custReceivedAt
    }
    return statusTime ? formatShortTime(statusTime) : ''
}

//0：待打包 1：已打包  2：配送中  3：已签收  4：问题件
export function formatLocalDeliveryStatusTime(localDelivery) {
    var statusTime = null;
    if (localDelivery.status == 1) {
        statusTime = localDelivery.packedAt
    } else if (localDelivery.status == 2) {
        statusTime = localDelivery.deliveryAt
    } else if (localDelivery.status == 3) {
        statusTime = localDelivery.custReceivedAt
    } else if (localDelivery.status == 4) {
        statusTime = localDelivery.deliveryAt
    } else {
        statusTime = localDelivery.createdAt
    }
    return statusTime ? formatShortTime(statusTime) : ''
}

export function formatDate(timestamp) {
    var date = new Date(timestamp);
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    var day = date.getDate()

    return [year, month, day].map(formatNumber).join('-')
}

export function formatNumber(n) {
    n = n.toString()
    return n[1] ? n : '0' + n
}

export function parseUrl(url) {

    if (!url)
        return {}

    // "redpacket?redPacketId=1"
    const qmark = url.indexOf('?')

    let path
    if (qmark >= 0)
        path = url.substring(0, qmark)

    const lastUrl = url.substring(qmark + 1)
    const parameters = lastUrl.split('&')
    let queryMap = {}
    parameters.forEach(value => {

        const params = value.split('=')
        if (params.length == 2)
            queryMap[params[0]] = params[1]
    });

    if (!queryMap.redirect)
        queryMap.redirect = "home"

    return {
        path: path,
        queryMap: queryMap
    }
}

export function base10To26(num) {

    let res = ''
    if (num === 0)
        res = "A"

    while (num > 0) {
        let remain = num % 26
        res = String.fromCharCode(remain + 65) + res
        num = (num - remain) / 26
    }
    return res
}

export function base26To10(num) {

    if (!num)
        return 0

    let res = 0
    for (let i = num.length - 1, j = 1; i >= 0; i--, j *= 26) {
        const c = num.charAt(i)
        const char = c.charCodeAt()

        if ('A'.charCodeAt() > char || char > 'Z'.charCodeAt())
            return 0

        res += (char - 65) * j
    }
    return res
}

export function getPriceBahtCent(originRmb) {
    const originRate = getExchangeRate()
    if (!originRate)
        return 0

    const rmb = parseFloat(originRmb)
    const rate = parseFloat(originRate)
    const baht = rmb * rate / 100
    return formatPrice(baht)
}

export function getPriceBahtYuan(originRmb) {
    const originRate = getExchangeRate()
    if (!originRate)
        return 0

    const rmb = parseFloat(originRmb).toFixed(2)
    const rate = parseFloat(originRate).toFixed(2)
    const baht = rmb * rate
    return formatPrice(baht)
}

export function formatPrice(price) {
    const baht = parseFloat(price).toFixed(2)
    return baht
}

export function transRMBtoTHB(originRmb) {
    const originRate = getExchangeRate()
    if (!originRate)
        return 0

    const rmb = parseFloat(originRmb).toFixed(2)
    const rate = parseFloat(originRate).toFixed(2)
    const baht = rmb * rate
    return formatPrice(baht)
}

export function formatPriceCeil(price) {
    return Math.ceil(price * 10) / 10
}

export function formatThValue(value) {
    if (!value.includes("$$$")) return value;
    const locales = value.split("$$$");

    if (locales.length >= 2) return locales[1];
    else return value;
}

export function formatSkuValue(sku) {
    if (!sku)
        return
    const skuValues = sku.split(' / ')
    const skus = skuValues.map((s) => {
        return formatThValue(s)
    })
    return skus.join(' / ')
}

import {
    getUserInfo
} from './token'

const insiders = ['A7ID', 'A5WS']

export function isInsiders() {
   
    
    const userInfo = getUserInfo()
    if (userInfo) {
        const {
            user
        } = userInfo
        const {
            code
        } = user
        return insiders.includes(code)
    }
    return false
}