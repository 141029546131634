import storage from './storage'
import {
    STORAGE_KEYS
} from './constants'
import store from '../store'
import router from '../router'

export function getAccessTokenWithValidStatus() {
    const oauth2 = JSON.parse(storage.get(STORAGE_KEYS.tokenKey));
    return {
        token: oauth2 && oauth2.value,
        isValid: isAccessTokenValid(oauth2)
    };
}


export function getAccessToken() {
    const oauth2 = JSON.parse(storage.get(STORAGE_KEYS.tokenKey));
    if (isAccessTokenValid(oauth2)) {
        return oauth2.value
    }
    return null;
}

export function getFullAccessToken() {
    const oauth2 = JSON.parse(storage.get(STORAGE_KEYS.tokenKey));
    if (isAccessTokenValid(oauth2))
        return oauth2
    return null;
}

//是否过期
function isExpired(expiration, mills) {
    const now = new Date().getTime();
    //提前1分钟判断
    const expired = expiration < now + mills;
    // console.log('now+5miniutes=%o,expiration=%o,expired=%o', formatTime(now + 1 * 60 * 1000), formatTime(expiration), expired);
    return expired;
}

export function getShopifyCustomer() {
    const oauth2 = JSON.parse(storage.get(STORAGE_KEYS.tokenKey));

    if (oauth2 && oauth2.shopifyCustomer) {
        const {
            shopifyCustTokenExpiredAt
        } = oauth2.shopifyCustomer

        const expired = isExpired(shopifyCustTokenExpiredAt, 24 * 60 * 60 * 1000)
        if (expired) {
            console.log('shopify token expired');
            store.commit("setTokenExpired", true)
            router.replace({
                name: 'login'
            })
            return expired
        }
        return oauth2.shopifyCustomer
    }
    return null
}

export function shopifyTokenExpired() {
    const oauth2 = JSON.parse(storage.get(STORAGE_KEYS.tokenKey));

    if (!oauth2 || !oauth2.shopifyCustomer)
        return false

    const {
        shopifyCustTokenExpiredAt
    } = oauth2.shopifyCustomer

    return isExpired(shopifyCustTokenExpiredAt, 24 * 60 * 60 * 1000)
}

export function setAccessToken(token) {
    storage.setObj(STORAGE_KEYS.tokenKey, token)
}

export function clearAccessToken() {
    storage.remove(STORAGE_KEYS.tokenKey)
}

export function getRefreshToken() {
    const oauth2 = JSON.parse(storage.get(STORAGE_KEYS.tokenKey));
    if (isRefreshTokenValid(oauth2)) {
        // console.log('get refresh token : ', oauth2.refreshToken.value)
        return oauth2.refreshToken.value;
    }
    return null;
}

function isAccessTokenValid(oauth2) {
    // console.log('isAccessTokenValid,oauth2=%o', oauth2);
    return oauth2 && oauth2.value && !isExpired(oauth2.expiration, 1 * 60 * 1000);
}

function isRefreshTokenValid(oauth2) {
    // console.log('isRefreshTokenValid,oauth2=%o',oauth2);
    return oauth2 && oauth2.refreshToken &&
        !isExpired(oauth2.refreshToken.expiration, 1 * 60 * 1000);
}

export function getUserInfo() {
    const userInfo = JSON.parse(storage.get(STORAGE_KEYS.userInfo));
    return userInfo;
}

export function setConfig(data) {
    storage.setObj(STORAGE_KEYS.beericher_config, data)
}

export function getConfig() {
    const data = JSON.parse(storage.get(STORAGE_KEYS.beericher_config));
    return data
}


export function getExchangeRate() {
    const data = getConfig()
    return data ? data.exchangeRate : data;
}

export function isLogin() {
    const fToken = getFullAccessToken()
    return fToken && fToken.value
}