import axios from 'axios'
import {
    Toast
} from "vant"
import {
    BASE_API,
    COMPANY
} from "./constants"
import {
    getAccessToken,
    clearAccessToken
} from "@/utils/token";
import router from '../router'
import store from '../store'
import {
    isInsiders
} from './utils'
import {
    errorMessage
} from "./apollo-utils"

const SERVER_API = BASE_API;

const noNeedTokenUrls = [
    'refreshToken', 'lineLogin', 'fetchConfig', 'lineWebLogin', 'authorization',
    'query/collections', 'query/products', 'query/product', 'ali1688', 'parseProductUrl',
    'cargo/fee/estimation', 'localRouteFee/estimation'
]

const service = axios.create({
    timeout: 30000,
    baseURL: SERVER_API,
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'logistic-company': COMPANY
    }
})

// 添加请求拦截器
service.interceptors.request.use(config => {
    let access_token = getAccessToken();

    const currentUrl = config.url
    const res = noNeedTokenUrls.filter(url => {
        return currentUrl.indexOf(url) != -1
    })

    if (!res || res.length === 0)
        if (access_token) {
            config.headers.Authorization = 'Bearer ' + access_token;
        }

    return config
})

service.interceptors.response.use(response => {
    if (response.status === 200) {
        const data = response.data
        const {
            errors
        } = data
        if (errors) {
            const err = errorMessage(errors)
            if (isInsiders())
                return {
                    ...data,
                    message: err
                }
        }
        if (data.code === 40004) {
            Toast.fail(data.message)
            clearAccessToken()
            store.commit("setTokenExpired", true)
            router.replace({
                name: 'login'
            })
        }
        return data
    }
}, error => {
    Toast.fail("network exception")
    return Promise.reject(error)
})

const request = {
    baseURL: SERVER_API,
    getDetails: (path, id, params) => {
        return service({
            url: path + '/' + id,
            params: params,
            method: 'GET'
        })
    },
    get: (path, params) => {
        return service({
            url: path,
            params: params,
            method: 'GET'
        })
    },
    post: (path, params, headers) => {
        return service({
            url: path,
            data: params,
            headers: headers
        })
    },
    put: (path, id, params) => {
        return service({
            url: path + '/' + id,
            data: params,
            method: 'PUT'
        })
    },
    delete: (path, id) => {
        return service({
            url: path + '/' + id,
            method: 'DELETE'
        })
    },
}

export default request